.register-button {
  text-decoration: none !important;
}

.page-title {
  text-align: center;
  font-size: xx-large;
  text-decoration-line: underline;
}

.leaderboard-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}
