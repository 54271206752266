@import '../../theme/variables.scss';

.profile-header {
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
}

h4 {
  display: flex;
  justify-content: center;
}

.profile-card-contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85vw;
}
.info-card-contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 85vw;
}

.about-list {
  flex-direction: row;
  justify-content: center;
}

// .info-row {
//   display: flex;
//   flex-direction: column;
//   justify-content: space-evenly;
//   align-items: center;
// }

.follow-list-item {
  display: flex;
  justify-content: space-around;
}

.follow-list-item > p{
  display: flex;
  align-items: center;
}

.account-cards-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
  margin-bottom: 2.5rem;
}

.scorecard-number {
  color: $pin-red;
}