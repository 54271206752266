.popup {
  position: fixed;
  top: 35%;
  left: 25%;
  width: 50%;
  height: 25%;
  border-radius: 25px;
  background-color: #1e2124;

  display: flex;
  justify-content: center;
  align-items: center;

}

.popup-inner {
  position: relative;
  padding: 10px;
  width: 100%;
  // height: 65%;
  color: #fffffe;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn-row {
  background-color: #fffffe;
  border-radius: 25px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

a {
  text-decoration: none;
}

