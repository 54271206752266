@import '../../theme/variables.scss';

.drawer-link {
  text-decoration: none !important;
  color: $text ;
}

.login-button {
  text-decoration: none !important;
  color: #fffffe;
}

.admin-button {
  text-decoration: none !important;
  color: #fffffe;
}