html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
}

.content {
  margin-top: 56px;
  flex-grow: 1;

  @media (min-width: 0px) and (orientation: landscape) {
    margin-top: 48px;
  }

  @media (min-width: 600px) {
    margin-top: 64px;
  }
}

.map-container {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: flex-end;
}

.map-container-full {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.custom-game-page{
  height: 100%;
}

.css-pc41rl{
  height: 100%;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.scoreboard {
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-style: inset;
  border-color: black;
  border-width: 0.4em;
  border-radius: 0% !important;

  &-buttons {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }

}

.login-register-buttons {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
